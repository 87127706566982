<template>
  <div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <hr>
    <b-row>
      <b-col md="9">
        <h2>Add Action Type</h2>
        <validation-observer ref="actionTypeSimple">
          <div class="white-bg-container">
            <b-row class="p-1">
              <b-col md="5">
                <b-form-group
                  label="Action Type Name"
                  label-for="actionType"
                >
                  <b-form-input
                    id="actionType"
                    v-model="ActionType"
                    placeholder=""
                    name="actionType"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <validation-provider
              #default="{ errors }"
              name="Action Type Class"
              rules="required"
            >
              <b-row class="p-1">
                <b-col md="5">
                  <b-form-group
                    label="Action Type Class"
                    label-for="actionClass"
                    class="required-data"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      id="actionClass"
                      v-model="ActionClass"
                      label="title"
                      :options="actionClassOption"
                      name="actionClass"
                      :state="errors.length > 0 ? false : null"
                    >
                    </v-select>
                    <small
                      class="text-danger"
                      :state="errors.length > 0 ? false:null"
                    >
                      {{ errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-provider>
            <b-row class="p-1">
              <b-col>
                <b-form-checkbox
                  id="preferPhysicalMedia"
                  v-model="IsBillable"
                  name="preferPhysicalMedia"
                  :disabled="readOnly"
                >
                  Action Type is Non-Billable
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
        </validation-observer>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="saveActionType()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>


  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { required } from '@validations'

const apiService = new APIService();

export default {
  components: {
    BBreadcrumb,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ActionTypeID: "",
      ActionType: "",
      ActionClass: "",
      ActionClassID: null,
      actionClassOption: [],
      IsBillable: false,

      readOnly: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
    }),
    breadcrumbItems: function() {
      return [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: "Utility",
          href: '/admin/utility'
        },
        {
          text: "Manage Action Types",
          active: true,
        },
      ]
    },
  },
  watch: {
    ActionClassID(val) {
      if (val) {
        this.ActionClass = this.actionClassOption.filter(i => i.value === val)[0];
      }
    },
    actionClassOption(val) {
      if (val && !this.ActionClass) {
        this.ActionClass = this.actionClassOption.filter(i => i.value === this.ActionClassID)[0];
      }
    },
  },
  async created() {
    this.getActiveClasses();
    try {
      this.ActionTypeID = this.$route.params.actionTypeID;
      if (this.ActionTypeID) {
        apiService
            .get("action/type/" + this.ActionTypeID + "/detail")
            .then(res => {
              this.ActionType = res.data.Name;
              this.IsBillable = res.data.IsNonBillable;
              this.ActionClassID = res.data.ActionClassID;
            })
      }
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    getActiveClasses() {
      apiService
          .get("action-class")
          .then(res => {
            this.actionClassOption = res.data.map(item => {return {title: item.name, value: item.id}});
          })
    },
    resetForm() {
      this.$router.push('/admin/utility/manage-action-types');
    },
    saveActionType() {
      this.$refs.actionTypeSimple.validate().then((success) => {
        if (success) {
          const postData = {
            "Name": this.ActionType,
            "IsNonBillable": this.IsBillable,
            "IsAdditionalReportPrepTimeAllowed": false,
            "ActionClassId": this.ActionClass.value
          };
          const path = this.ActionTypeID ? 'action/type/' + this.ActionTypeID + '/edit' : "action/type/create";
          apiService
              .post(path, postData)
              .then((res) => {
                if (res) {
                  this.showToast('success', 'top-center', 4000, 'Action Type added!');
                  this.$router.push('/admin/utility/manage-action-types');
                }
              });
        }
      })
    },
  }
}
</script>

<style scoped>

</style>